<template>
  
  <div class="prgl"> 

    <div 
      class="prog"
      :style="{
        width: percentCalc + '%'
      }"
      >
      <div v-if="showText" class="txt">
        {{ percentCalc }}%
      </div>
    </div>
    
        
  </div> 

</template>

<script setup lang="ts">


interface PageProps {
  percent?: number,
  showText?: boolean  
}

const props = withDefaults(defineProps<PageProps>(), {
  percent: 0,
  showText: true  
})

const percentCalc = ref(props.percent);

watch(() => props.percent, (new_percent) => {
  percentCalc.value = new_percent;
}, { deep: true })


</script>

<style scoped>
 
.prgl{
  width: 100%;
  height: 28px;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.prog{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--un-primary-back-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  padding: 0 5px;
  box-sizing: border-box;
  transition: width 0.5s;
}

.txt{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  padding: 0 5px;
  box-sizing: border-box;
  transition: width 0.5s;
}

</style>
