<template>

  <div class="param smell">

    <div :class="'setup_ico ' + ico"></div>

    <div class="bx">
      <div class="vl">
        
        <div :class="'smell_size ' + (num ? 'active' : '')">
          
          <div 
            v-for="(i,k) in WeekPropsSmellEnumTr"
            :class="'smell_item size_' + k + ' ' + (num === k ? 'active' : '')" @click="change(k)">
            <i :class="'setup_icon ' + WeekPropsSmellEnumIcon[k]"></i>
            <div class="name">{{ $t(WeekPropsSmellEnumTr[k]) }}</div>
          </div>
        </div>

      </div> 
    </div>

    <div class="hnt">
      {{ param }}
    </div>
     
  </div>
  
</template>


<script setup lang="ts">

import { WeekPropsSmellEnum, WeekPropsSmellEnumIcon, WeekPropsSmellEnumTr } from '@/types/diary';

interface PageProps {
  num: WeekPropsSmellEnum;
  ico: string;
  param?: string;
}

const props = defineProps<PageProps>();

const emits = defineEmits<{
  'update:num': (vl: WeekPropsSmellEnum) => void;
}>();

const change = (vl: WeekPropsSmellEnum) => {
  emits('update:num', vl);
};

</script>
 


<style scoped>
  @import '@/assets/css/param.css';
</style>


